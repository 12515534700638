
import { Vue, Options } from 'vue-class-component'
import { SessionModule } from '@/store/modules/session'

@Options({
  name: 'HomeView',
})
export default class HomeView extends Vue {
  get isSignedIn(): boolean {
    return SessionModule.isSignedIn
  }

  get isSigningIn(): boolean {
    return SessionModule.isSigningIn
  }
}
